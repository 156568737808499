import React from 'react';
import { Button, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useState, useContext, useRef } from 'react';
import { AppContext } from './AppContext';

export const Reporte = (props) => {
    const edoGlobal = useContext(AppContext);
    //console.log(props.usuario.Id);
    const [cargando, setCargando] = useState(false);
    const [contactar, setContactar] = useState(false);
    let idArchivo = useRef("");

    async function guardar(){
        let texto = document.getElementById("txt").value;
        let url = `${edoGlobal.urlws}/home/crearReporte`;
        let archivo = idArchivo.current;
        let dato = { 
            Usuario : props.usuario.Id, 
            Texto : texto, 
            Contactar : contactar ?"S":"N",
            Archivo : archivo
        };
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res == "ok"){
            Swal.fire('Atencion','reporte enviado con exito ','success');
            await crearAlerta(texto,archivo);
            document.getElementById("txt").value = "";
            idArchivo.current = "";
        }else
            Swal.fire('Atencion',res,'error');
    }
    async function subir(){
        const { value: file } = await Swal.fire({
            title: 'Subir archivo de evidencias',
            input: 'file',
            inputAttributes: {
                //'multiple': 'multiple',
                'aria-label': 'Seleccione archivo'
            }
          });
          
          if (file) {
            setCargando(true);
            const data = new FormData()
            data.append('file', file);
            data.append('id', props.usuario.Id);    
            let url = `${edoGlobal.urlws}/home/subirEvidencias`;
            const resp = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            setCargando(false);
            if(res.substring(0,2) == "Er")
                Swal.fire('Atencion',res,'error');
            else{
                idArchivo.current = res;
            }    
          }        
    }

    async function crearAlerta(texto, archivo){
        let url = `https://www.bankapi.isaerpweb.com/api/alerta/crearalerta`;
        //url = "https://localhost:7296/api/alerta/crearalerta";
        let archivos = [];        
        archivos.push( {
            id: 0,
            idAlerta: 0,
            urlDocumento: "https://www.buzonoporfin.isaerpweb.com/content/evidencias/" + archivo,
            nombreDocumento: archivo
          });
        let dato = {
            id : 0,
            idTipoAlerta : 2,
            valor: "Descripcion[:]" + texto + "|Remitente[:]" + (contactar ? props.usuario.Nombre : "ANOMINO"),
            fecha: new Date(),
            documentos: archivos              
        };
        setCargando(true);
        let json = JSON.stringify(dato);
        console.log("alerta",json);
        // /*
        const resp = await fetch(url,{
            method: 'POST',
            body: json,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            }
          });
        const res = await resp.text();
        setCargando(false);
        if(res == "ok" || res == '"OK"')
            Swal.fire('Atencion','alerta creada con exito ','success');
        else
            Swal.fire('Atencion',res,'error');
        // */
    }

    return(
        <>
            <div className="container">
                <br />
                <div className="row" >
                    <div className="col-1">
                    </div>
                    <div className="col-10">
                        <div>
                            <div className="card card-outline card-primary">
                                <div className="card-header text-center">
                                    <img src="imagenes/logoCh.png" alt="oporfin" />
                                </div>
                                <div className="card-body">
                                    <h3 className="login-box-msg">Medio de Denuncia Interno</h3>
                                    <h4>Instrucciones</h4>
                                    <p>
                                        A continuación detalla tu reporte de actividades internas preocupantes, el cual se enviará de manera confidencial al oficial de cumplimiento, quien está obligado a investigar y dar seguimiento a tu reporte. Considera que:
                                    </p>
                                    <ol>
                                        <li>Debes asegurarte de incluir el nombre y puesto de la persona que reportas.</li>
                                        <li>No debes incluir información personal ya que este es un reporte anónimo.</li>
                                        <li>No se guarda información de la procedencia de este reporte, se guarda solo el reporte y la hora en que se genera.</li>
                                        <li>En caso de tener problemas con el envío de reportes por favor da aviso a soporte@isaerpweb.com</li>
                                    </ol>
                                    <h6>¿ Esta dispuesto a ser contactado para seguimiento ? (su denuncia seguira siendo confidencial) <input type="checkbox" checked={contactar} onChange={e => setContactar(e.target.checked)} /></h6>
                                    <h4>Descripcion del reporte</h4>
                                    <textarea id="txt" className="form-control" rows="10" />
                                    <div style={{ height: 4 }}></div>
                                    <Button color="primary" onClick={subir} ><img src="imagenes/explorar.png" alt="subir archivo" />Subir archivo de evidencias</Button>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Button color="secondary" onClick={guardar}>Enviar reporte</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary" />
                </div>
                :
                null
            }

        </>
    )
}